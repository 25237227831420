import { Dispatch, useRef } from 'react';
import moment from 'moment/moment';
import { HeadsetInputReducerAction } from './reducer';
import { useDebouncedCallback } from '../../helpers';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { addOneToast, selectAllCalls } from '../../redux/slices';

export const yeaLinkOperations = {
    offHook: 0b0001,
    onHook: 0b0000,
    mute: 0b0010,
    unmute: 0b0000,
    ring: 0b0100,
    ringOff: 0b0000,
    hold: 0b1000,
    resume: 0b0001
};

let lastFail: number | null = null;

export type YeaLinkOps = keyof typeof yeaLinkOperations;

interface Props {
    reducer: Dispatch<HeadsetInputReducerAction>;
}

export const useConnectedHeadsets = ({ reducer }: Props) => {
    const inputReportRef = useRef<number[]>([]);
    const sendReport = useRef<boolean>(false);
    const previousReportRef = useRef<number[]>([]); // Ref to store the previous array
    const prePreviousReportRef = useRef<number[]>([]); // Ref to store the pre-to-previous array
    const sequenceTimerRef = useRef<NodeJS.Timeout | null>(null);
    const sequenceBufferRef = useRef<number[]>([]);
    const inputTimestampsRef = useRef<number[]>([]);
    const MAX_SEQUENCE_TIME = 200;

    const dispatch = useTypedDispatch();

    const calls = useTypedSelector(selectAllCalls);

    const handleDebouncedReports = useDebouncedCallback(
        devices => {
            if (inputReportRef.current.length > 0) {
                const previousArray = previousReportRef.current;
                const currentArray = inputReportRef.current;
                const prePreviousArray = prePreviousReportRef.current; // Access pre-to-previous array

                // YeaLink UH37
                if (devices.productId === 45578) {
                    if (
                        calls.length === 2 &&
                        previousArray.at(-2) === 5 &&
                        previousArray.at(-1) === 1
                    ) {
                        // when there are two calls then press hold, so it will hold on to the mute value that was set before the call was put on hold
                        reducer({
                            type: 'newReport',
                            payload: 59
                        });
                    } else if (
                        [1, 0].every((val, i) => val === currentArray[i]) &&
                        currentArray.length === 2
                    ) {
                        return;
                    } else if (currentArray[currentArray.length - 1] === 12) {
                        reducer({
                            type: 'newReport',
                            payload: 12
                        });
                    } else if (currentArray.at(-2) === 5) {
                        // this will check when the call is accepted and check if the mic is muted if muted send 5
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (
                        calls.length > 1 &&
                        previousArray[0] === 9 &&
                        previousArray[1] === 1 &&
                        currentArray[0] === 1 &&
                        currentArray[1] === 1 &&
                        currentArray[2] === 0 &&
                        currentArray.length === 5
                    ) {
                        // when there are two calls then press hold so it will hold on to the mute value that was set before the call was put on hold
                        reducer({
                            type: 'newReport',
                            payload: 59
                        });
                    } else if (
                        calls.length === 1 &&
                        previousArray[0] === 9 &&
                        previousArray[1] === 1 &&
                        currentArray[0] === 1 &&
                        currentArray[1] === 1 &&
                        currentArray[2] === 0 &&
                        currentArray.length === 5
                    ) {
                        // Checks if the call is on hold. When the call is on hold, mute input functionality stops.
                        // Therefore, when the call is resumed, if the microphone is muted, it sends a command to unmute.
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (currentArray.at(-2) === 4) {
                        // this will detect the mic movement if up than mute or if down than unmute
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (previousArray[0] === 9 && currentArray[0] === 1) {
                        // this will end the call sending 0 to the reducer
                        reducer({
                            type: 'newReport',
                            payload: currentArray[1]
                        });
                    } else if ([16, 0].every((val, i) => val === currentArray[i])) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if (
                        [1, 0, 1, 0].every(
                            (val, i) => val === currentArray[i] && currentArray.length === 4
                        ) &&
                        [1, 1, 0, 1, 0].every((val, i) => val !== previousArray[i])
                    ) {
                        // this will check if the response contains 1010 if yes than send 0 which will end the call and also check the previous array does not contain 10 as that is generated when we use volume up button
                        // and its vol up as call cancel
                        reducer({
                            type: 'newReport',
                            payload: currentArray[1]
                        });
                    } else {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    }
                }

                // YeaLink WH62
                if (devices.productId === 45079) {
                    if (currentArray[1] === 0 && currentArray[0] === 1) {
                        reducer({
                            type: 'newReport',
                            payload: inputReportRef.current[1]
                        });
                    } else if (
                        previousArray[0] === 9 &&
                        currentArray[0] === 1 &&
                        currentArray[1] === 59
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (
                        currentArray[1] === 5 &&
                        currentArray[0] === 1 &&
                        currentArray[2] === 1 &&
                        currentArray[3] === 59
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[1]
                        });
                    } else if (currentArray[0] === 19 && currentArray[1] === 3) {
                        reducer({
                            type: 'newReport',
                            payload: 16
                        });
                    } else if (
                        previousArray[0] === 5 &&
                        previousArray[1] === 1 &&
                        previousArray[2] === 59 &&
                        currentArray[0] === 59
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else {
                        // Default case
                        reducer({
                            type: 'newReport',
                            payload: inputReportRef.current[0]
                        });
                    }
                }

                // Yealink UH38
                if (devices.productId === 45574) {
                    if (
                        [2, 3, 1, 0].every(
                            (val, i) => val === currentArray[i] && currentArray.length === 4
                        )
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if (
                        [1, 0, 5, 1].every(
                            (val, i) => val === currentArray[i] && currentArray.length === 4
                        )
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[2]
                        });
                    } else if ([9, 1, 1, 0].every((val, i) => val === currentArray[i])) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if ([16, 0].every((val, i) => val === currentArray[i])) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if ([19, 3].every((val, i) => val === currentArray[i])) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if ([64, 0].every((val, i) => val === currentArray[i])) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if (
                        currentArray.at(-2) === 5 &&
                        currentArray.at(-1) === 1 &&
                        previousArray[0] === 2
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[5]
                        });
                    } else if (
                        [8, 0, 1, 0].every((val, i) => val === currentArray[i]) &&
                        previousArray[0] === 12
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 9
                        });
                    } else if (
                        previousArray[0] === 9 &&
                        previousArray[1] === 1 &&
                        [1, 1, 0, 1, 0, 5, 1].every((val, i) => val === currentArray[i])
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (
                        currentArray[0] === 5 &&
                        currentArray[1] === 1 &&
                        [1, 1, 0, 1, 0, 5, 1].every((val, i) => val === previousArray[i])
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (currentArray[0] === 12) {
                        reducer({
                            type: 'newReport',
                            payload: 12
                        });
                    }
                }

                // Yealink BH76
                if (devices.productId === 45114) {
                    if (currentArray[0] === 0 && currentArray[1] === 0) {
                        // this sequence is for the vol up button that causes to end the call so will not send any command
                        reducer({
                            type: 'newReport',
                            payload: 59 // this is junk value to stop the call
                        });
                    } else if (currentArray[0] === 2 && currentArray[1] === 3) {
                        // end cal
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if (
                        [1, 0].every(
                            (val, i) => val === currentArray[i] && currentArray.length === 2
                        )
                    ) {
                        // end call
                        reducer({
                            type: 'newReport',
                            payload: currentArray[1]
                        });
                    } else if ([16, 0].every((val, i) => val === currentArray[i])) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if (
                        [4, 1].every(
                            (val, i) => val === currentArray[i] && currentArray.length === 2
                        )
                    ) {
                        // mute call
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (
                        [1, 5, 1].every(
                            (val, i) => currentArray[i] !== undefined && val === currentArray[i]
                        ) &&
                        (previousArray[0] === 9 || previousArray[0] === 2 || previousArray[0] === 0)
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 5
                        });
                    } else if (
                        calls.length > 1 &&
                        currentArray.at(-2) === 5 &&
                        currentArray.at(-1) === 1 &&
                        [9, 1].every((val, i) => val === previousArray[i]) &&
                        prePreviousArray.at(-2) === 5 &&
                        prePreviousArray.at(-1) === 1
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 59
                        });
                    } else {
                        // Default case
                        reducer({
                            type: 'newReport',
                            payload: inputReportRef.current[0] // Handle default payload
                        });
                    }
                }
                // Jebra Evolve2 65
                if (devices.productId === 11856) {
                    if (
                        [1].every((val, i) => val === currentArray[i] && currentArray.length === 1)
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 2
                        });
                    } else if ([16, 0].every((val, i) => val === currentArray[i])) {
                        reducer({
                            type: 'newReport',
                            payload: currentArray[0]
                        });
                    } else if (
                        [2].every((val, i) => val === currentArray[i] && currentArray.length === 1)
                    ) {
                        reducer({
                            type: 'newReport',
                            payload: 12
                        });
                    }
                }

                prePreviousReportRef.current = [...previousReportRef.current]; // Update pre-to-previous
                previousReportRef.current = [...inputReportRef.current];

                inputReportRef.current = [];
            }
        },
        [],
        500
    );

    const filters = [
        {
            usage: 0x0005,
            usagePage: 0x000b
        }
    ];

    async function requestDevice() {
        try {
            const [device] = await navigator.hid.requestDevice({ filters });

            if (!device) {
                console.warn('Invalid device selected');
                return;
            }

            connectDevice(device).catch(() => {
                console.warn('Failed to connect to device');
            });
        } catch (e) {
            console.error('Failed getting hid headset');
        }
    }

    async function connectDevice(device: HIDDevice) {
        device
            .open()
            .then(() => {
                const value = 0x00;

                device
                    .sendReport(0x02, new Uint8Array([value]))
                    .then(() => {
                        sendReport.current = true;
                    })
                    .catch(() => {
                        sendReport.current = false;
                    })
                    .finally(() => {
                        device.addEventListener('inputreport', handleInputReport);
                        reducer({
                            type: 'setHeadset',
                            payload: device
                        });
                    });
            })
            .catch(() => {
                console.warn('Failed connecting device');
            });
    }

    function handleInputReport(event: HIDInputReportEvent) {
        const { data, device } = event;
        const value = data.getUint8(0);

        sequenceBufferRef.current.push(value);
        inputTimestampsRef.current.push(Date.now());

        if (sequenceTimerRef.current) clearTimeout(sequenceTimerRef.current);

        sequenceTimerRef.current = setTimeout(() => {
            const sequenceString = sequenceBufferRef.current.join(',');
            const isWithinTime =
                inputTimestampsRef.current[inputTimestampsRef.current.length - 1] -
                    inputTimestampsRef.current[0] <=
                MAX_SEQUENCE_TIME;

            if (sequenceString === '1,0,1,0' && isWithinTime) {
                inputReportRef.current.push(12);
            } else {
                sequenceBufferRef.current.forEach(val => inputReportRef.current.push(val));
            }

            handleDebouncedReports(device);

            sequenceBufferRef.current = [];
            inputTimestampsRef.current = [];
        }, 50);
    }

    function handleOperation(opList: YeaLinkOps[], device?: HIDDevice) {
        if (!sendReport.current || !device) return;

        let bitwiseOperation = 0b0000;

        opList.forEach(operation => {
            bitwiseOperation += yeaLinkOperations[operation];
        });

        device.sendReport(0x02, new Uint8Array([bitwiseOperation])).catch(error => {
            console.error('Failed to communicate with device', error);

            let sendNotification = true;
            const nowUnix = moment().unix();

            if (lastFail) {
                // Debounce this to 1 time per minute
                sendNotification = lastFail < nowUnix - 60;
            }

            if (sendNotification) {
                lastFail = nowUnix;
                dispatch(
                    addOneToast({
                        title: `Failed to communicate with ${device.productName}`,
                        content: '',
                        type: 'error'
                    })
                );
            }
        });
    }

    return {
        requestDevice,
        handleOperation,
        connectDevice
    };
};
